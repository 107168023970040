.podcast-header-title {
  font-size: 36px;
  font-weight: 400;
  line-height: 0px;
}

.podcast {
  width: 600px;
  border-radius: 10px;
  box-shadow: 0 8px 46px 0 rgba(7, 218, 233, 0.274),
    0 2px 25px 0 rgba(7, 218, 233, 0.274);
}

.podcast-main-div {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 1rem 1rem;
}
@media (max-width: 768px) {
  .podcast-header-title {
    font-size: 30px;
    text-align: center;
  }
  .podcast-header-subtitle {
    font-size: 16px;
    line-height: normal;
    text-align: center;
  }

  .podcast {
    width: 300px;
  }
  .podcast-main-div {
    text-align: center;
  }
}
