.footer-text {
  text-align: center;
  color: #1b8aca;
  font-weight: bold;
  box-shadow: 0 8px 46px 0 #56198bbb, 0 2px 25px 0 #56198bbb;
}

.footer-div {
  margin-top: 2rem;
}
