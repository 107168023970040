.myProjects-cards-div {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(290px, 1fr));
  gap: 1rem 1rem;
  border: solid 2px;
  border-color: rgb(255, 255, 255);
  border-radius: 10px;
}

.myProjects-heading {
  font-size: 48px;
  font-weight: 400;
}

.myProjects-container {
  width: 90%;
  padding: 20px 10px;
  margin: 0px auto;
  margin-top: 2rem;
}

.button-liveProjects-div {
  display: flex;
  margin-top: 20px;
  align-items: center;
  align-content: center;
  justify-content: center;
}

.experience-container > * {
  flex: 1;
}

.butt{
  color: white;
}

.butt:hover{
  color:black;
}

/* Media Query */

@media (max-width: 1380px) {
  .experience-heading {
    font-size: 40px;
  }
}

@media (max-width: 768px) {
  .experience-heading {
    font-size: 30px;
    text-align: center;
  }
}

/* @media (min-width: 768px) {
  .button-liveProjects-div {
    padding-left: 30px;
  }
}

@media (min-width: 768px) and (max-width: 1380px) {
  .button-liveProjects-div {
    padding-left: 60px;
  }
}

@media (min-width: 1380px) {
  .button-liveProjects-div {
    padding-left: 40px;
  }
} */
