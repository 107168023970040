.contact-div-main {
    display: flex;
}

.contact-div-main > * {
    flex: 1;
}

.contact-title {
    font-size: 40px;
    font-weight: 400;
}

.contact-detail,
.contact-detail-email {
    margin-top: 20px;
    font-size: 16px;
    font-weight: 400;
    color: #0578ec;
    text-decoration: none;
}

.contact-div-main{
    background-color: rgb(255, 255, 255);
    border-radius: 10px;
    max-height: 500px;
}

.contact-header{
    position: relative;
    background-color: rgb(255, 255, 255);
    box-shadow: rgba(0, 0, 0, 0.2) 0px 10px 30px -15px;
    border-radius: 10px;
    border: 1px solid rgba(211, 211, 211, 0.397);
    color: #000;
    padding-top: 60px;
    box-shadow: 0 8px 46px 0 rgba(7, 218, 233, 0.274),
    0 2px 25px 0 rgba(7, 218, 233, 0.274);
    
}

.contact-detail:hover,
.contact-detail-email:hover {
    color: black;
    text-shadow: 2px 1px 2px #b5b5b5;
    transition: all 0.3s;
}

.contact-text-div {
    margin-top: 1.5rem;
}

.contact-margin-top {
    margin-top: 4rem;
}

.contact-image-div > img {
    max-width: 100%;
    height: 80%;
    border-radius: 10px;
}

/* Media Query */
@media (max-width: 1380px), (max-width: 768px) {
    .contact-title {
        font-size: 56px;
        font-weight: 400;
        text-align: center;
    }

    .contact-title {
        font-size: 40px;
        text-align: center;
    }

    .contact-subtitle {
        font-size: 16px;
        line-height: normal;
        text-align: center;
    }

    .contact-text-div {
        text-align: center;
        margin-top: 1.5rem;
    }

    .contact-detail,
    .contact-detail-email {
        font-size: 20px;
    }

    .contact-image-div {
        display: none;
    }
}
