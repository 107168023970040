#main-content-box {
  position: relative;
  background-color: rgb(255, 255, 255);
  box-shadow: rgba(0, 0, 0, 0.2) 0px 10px 30px -15px;
  border-radius: 10px;
  border: 1px solid rgba(211, 211, 211, 0.397);
  color: #000;
  padding: 30px;
  box-shadow: 0 8px 46px 0 rgba(7, 218, 233, 0.274),
    0 2px 25px 0 rgba(7, 218, 233, 0.274);
}

.container form {
  width: 100%;
  padding: 10px;
}

.container input,
.container textarea {
  width: 100%;
  margin: 0 auto;
  padding: 10px;
  border: none;
  font-size: 16px;
  height: 30px;
  background-color: rgb(255, 255, 255);
  box-shadow: rgba(0, 0, 0, 0.2) 0px 10px 30px -15px;
  border-radius: 10px;
  border: 1px solid rgba(211, 211, 211, 0.397);
  margin-right: 30px;
}

#messagebody {
  height: 100px;
}

.container input:focus,
.container textarea:focus {
  box-shadow: 1px 1px 4px 1px rgb(49, 170, 240);
}

.singleItem {
  margin: 10px 0;
  background-color: rgb(255, 255, 255);
  box-shadow: rgba(0, 0, 0, 0.2) 0px 10px 30px -15px;
  border-radius: 10px;
  border: 1px solid rgba(211, 211, 211, 0.397);
  margin-right: 30px;
}

.btn {
  width: 100%;
  text-align: center;
}

.btn button {
  width: 200px;
  height: 50px;
  font-weight: bold;
  background-color: #011e20;
  color: white;
  border-radius: 10px;
  font-size: 18px;
  letter-spacing: 0.2rem;
  padding: 5px 10px;
  border: 1px solid transparent;
  outline: none;
  transition: all 0.5s ease-in-out;
  position: relative;
  margin-top: 5px;
}

.btn button:hover {
  border: 1px solid #fefefe;
  background-color: rgb(199, 202, 199);
  color: black;
  cursor: pointer;
}

.msg {
  text-align: center;
  margin: 0 auto;
  margin-bottom: 10px;
  letter-spacing: 0.2rem;
  font-style: italic;
  width: 340px;
  padding: 3px;
  font-size: 16px;
  border-radius: 5px;
  font-weight: bold;
  display: none;
  color: rgb(5, 110, 5);
  background-color: white;
}

.failed{
  color: red
}

.msgAppear {
  display: block;
}

@media screen and (min-width: 786px) {
  .container {
    max-width: 40%;
    margin: 100px auto;
    box-shadow: 1px 1px 4px 1px lightgrey;
    background-color: lightseagreen;
  }
}
