.centerContent {
  text-align: center;
  margin: auto;
  display: block;
  padding: 1.5rem;
}
.tw-main-div {
  border-radius: 10px;
  margin: auto;
  width: 75%;
  padding: 10px;
}
.twitter-component {
  width: auto;
  padding: 1.5rem;
}
.timeline-Header-title {
  font-size: 56px;
  font-weight: 100 !important;
}

.social-heading {
  font-size: 56px;
  font-weight: 400;
}

@media (max-width: 1380px) {
  .social-heading {
    font-size: 40px;
  }
}

@media (max-width: 768px) {
  .social-heading {
    font-size: 30px;
    text-align: center;
  }
  .tw-main-div {
    border-radius: 10px;
    margin-left: 0 15px 0 15px;
    width: 100%;
    padding: 10px;
  }
}
